import { Link } from "react-router-dom";
import Arrow_icon from "../../Assets/icons/ArrowUpRight.svg";
const BlogPosts = ({Title, Date, Author_first_Name,Summary, link, }) => {
  return (
    <div className='lg:px-[24px] px-[16px] py-[40px] rounded-lg border-[1px] border-P50 lg:w-[500px] '>
        <h3 className='text-[24px] text-P200 w-[266px] leading-[36px] font-semibold'>{Title}</h3>
        <div className='flex flex-row font-normal text-P400 text-[14px] mt-[16px] gap-[5px]'>
            <span>{Date}</span>
            <span> . {Author_first_Name} </span>
        </div>
        <p className="text-P200 font-normal leading-[27px] my-[32px]">{Summary}
        </p>
        <div className='flex justify-end items-center text-S400 font-medium'>   
            <Link to={link} className="hover:underline hover:underline-offset-4">Read more </Link>
            <img src={Arrow_icon} alt="arrow icon" />
        </div>
    </div>
  )
}

export default BlogPosts
