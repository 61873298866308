import React, {useState} from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"

const Subscribe = ({mainContainerStyle, containerStyle,  hStyle, pStyle, formStyle, buttonStyle}) => {
  const postUrl = `https://gmail.us21.list-manage.com/subscribe/post?u=53b207ecb6330c6e16cf2ba18&id=649e995cdc`

  const initialValues  = {name: "", email: ""};
  const errors = { name: "", email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(errors);
  const [message, setMessage] = useState("");


  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    setFormErrors(validate(formValues))
  };

  const handleSubmit = async (e, status, subscribe) => {
    e.preventDefault(); 
    subscribe({MERGE0:formValues.email, MERGE1:formValues.name})
    setFormErrors(validate(formValues))

    if (status === "error") {
      setMessage("Oops! An error occured, please try again.");
    } else{
      if(status==="success"){
        setMessage("You've sucessfully subscribed!");
        setFormValues(initialValues)
    }
  }
        setTimeout(()=>{
            setMessage(null);
        },6000);


  }

  const validate = (values) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email && !values.name) {
      errors.email = "Email address is rquired";
      errors.name = "Your name is rquired";
    } else if(!values.email){
      errors.email = "Email address is rquired";
    } 
    else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }
    if (!values.name) {
      errors.name = "Your name is rquired";
    } 
    return errors;
  };



  return (
    <div data-aos="fade-up" className={mainContainerStyle}>
      <div className={containerStyle}>
        <h2
          className={hStyle}
        >
          Join our community
        </h2>
        <p
          className={pStyle}
        >
          Be the first to receive updates on our forums, Geospatial new and
          activities, please fill in your email address below.
        </p>
      </div>
      <MailchimpSubscribe url={postUrl} 
      render={({ subscribe, status}) =>  (
          <form className={formStyle} onSubmit={(e) => handleSubmit(e, status, subscribe)}>
            <div className="h-31 mb-26">
             {message && <div className="text-center" data-aos="zoom-out"><br></br>
             <span className="lg:text-lg text-base bg-S300 text-white lg:px-32 py-8 px-16  text-center rounded" >
              {message}
             </span></div>}
             </div>
          <div className="lg:mb-32 mb-16">
            <label className="text-P200 lg:text-lg text-lg leading-27 font-medium">
              Name
            </label>
            <input
              type="text"
              placeholder="First Lastname"
              className="border-solid border-P75 border-2 outline-none rounded text-75 text-lg text-lg h-48 py-9.5 px-20 w-full"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <span className="text-error">{formErrors.name}</span>
          </div>
          <div className="lg:mb-48 mb-24">
            <label className="text-P200 lg:text-lg text-lg leading-27 font-medium">
              Email
            </label>
            <input
              type="text"
              placeholder="example@gmail.com"
              className="border-solid border-P75 border-2 outline-none rounded text-75 lg:text-lg text-lg h-48 py-9.5 px-20 w-full"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
            <span className="text-error">{formErrors.email}</span>
          </div>
          <div className="text-center">
            <button className={buttonStyle}>
              Submit
            </button>
          </div>
        </form>
      )}
      />
     
    </div>
  );
};

export default Subscribe;
