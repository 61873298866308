import React from "react";
import Subscribe from "./Subscribe";

const Subscribe2 = () => {
  return (
    <div>
      <Subscribe 
      mainContainerStyle ="grid lg:grid-cols-2 grid-cols-1 bg-P300 lg:px-96 px-16 lg:py-56  gap-24"
      containerStyle="text-white lg:py-64 py-24 lg:pl-16 lg:w-395 w-full space-y-24 flex flex-col lg:text-left text-center"
      hStyle="font-semibold lg:text-2xl text-xl lg:leading-41.6 leading-36"
      pStyle="font-normal lg:leading-36 lg:text-xl text-lg leading-27"
      formStyle="w-inherit space-y-32 bg-white px-20 py-32 lg:p-48 rounded-md"
      buttonStyle="py-16 px-64 bg-S300 text-white text-lg font-medium rounded hover:scale-95 transition-transform"
      />
    </div>
  );
};

export default Subscribe2;
