import React from "react";
import { NavLink } from "react-router-dom";
import africa from "../../Assets/icons/africa_map.svg";
import gis4africa from "../../Assets/logo/gis4africa_white.svg";
import { socialMediaLinks, footerlinks } from "../../Utils/data";

const Footer = () => {
  return (
    <div className="lg:h-366 bg-P300 w-full text-white grid lg:grid-cols-12 grid-cols-2">
      <div className="lg:col-span-9 col-span-1 lg:pl-96 pl-16 lg:pt-66 pt-30 flex lg:flex-row flex-col lg:gap-83">
        <div className="w-max lg:w-full">
          <NavLink to="/">
            <img src={gis4africa} alt="logo" className="w-233 h-fit" />
          </NavLink>
          <p className="mt-13.5 text-S50 leading-27 font-medium">
            Proferring GIS solution accross Africa
          </p>
          <div className="block text-center mt-46 pb-32 lg:hidden">
            <span className="font-normal leading-19.6 text-S50 text-base">
              © 2023 GIS4Africa All Rights Reserved.
            </span>
          </div>
          <ul className=" flex-row gap-3 mt-46 hidden lg:flex">
            {socialMediaLinks.map((social, id) => {
              return (
                <a
                  key={id}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>
                    <img
                      src={social.icon}
                      alt={social.id} 
                      className="hover:opacity-50 tranisition-transform w-fit h-fit"
                    />
                  </li>
                </a>
              );
            })}
          </ul>
        </div>
        <div className="w-inherit flex flex-col gap-74 lg:order-last order-first">
          <div className="grid lg:grid-cols-3 grid-cols-2 lg:gap-114 lg:gap-x-98 gap-y-32 gap-x-56 w-max lg:pt-20 z-10">
            {footerlinks.map((footLinks, id) => {
              return (
                <ul key={id} className="space-y-4 col-span-1">
                  <li className="mb-16">{footLinks.name}</li>
                  {footLinks.linkContent.map((linkContent, id) => {
                    return (
                      <NavLink key={id} to={linkContent.link}>
                        <li className="hover:text-S300 font-normal text-lg mb-16">
                          {linkContent.link_name}
                        </li>
                      </NavLink>
                    );
                  })}
                </ul>
              );
            })}
            <ul className="flex flex-row gap-2 block lg:hidden">
              {socialMediaLinks.map((social, id) => {
                return (
                  <a
                    key={id}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-5"
                  >
                    <li>
                      <img
                        src={social.icon}
                        alt={social.id}
                        className="hover:text-S300 w-full h-full"
                      />
                    </li>
                  </a>
                );
              })}
            </ul>
          </div>
          <div className="lg:block pl-12% hidden">
            <span className="font-normal leading-19.6 text-S50 text-base">
              © 2023 GIS4Africa All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
      <div className="lg:col-span-3 col-span-1 relative flex justify-end items-end overflow-hidden z-0">
        <img
          src={africa}
          alt="africa_map"
          className="h-299 w-322 lg:relative absolute lg:right-0 lg:bottom-0 -bottom-9"
        />
      </div>
    </div>
  );
};

export default Footer;
