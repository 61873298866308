import React,{useEffect} from 'react'
import { NavLink} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from '../Component/Atoms/Button';

const ErrorPage = () => {
    const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");    
    }, 6000);
  }, [navigate]);


  return (
    <div className="lg:p-96 py-233 px-16 flex flex-col items-center justify-center text-center">
      <h2 className='font-bold text-P400 lg:text-6xl text-5xl'>404</h2>
      <span className='font-medium lg:text-xl text-lg leading-36 pt-16 text-center'>Oops!...the page you’re looking for can’t be found</span>
      <NavLink to="/" className='mt-64'><Button name="Go back home" url="/" /></NavLink>
      <span className='pt-16'>Redirecting to home page.......</span>
    </div>
  )
}

export default ErrorPage
