import { MapContainer, TileLayer, Marker, Popup}from 'react-leaflet';
import L from 'leaflet';
import React from 'react';
import Icon from '../../Assets/icons/Marker.svg';

const Map = () => {
    const myIcon = L.icon({
        iconUrl: Icon
    })

  return (
    <div  className='my-128 lg:mx-24 mx-16  border-2 border-Gray-200 lg:rounded-none rounded'>
    <MapContainer className='w-full z-0' center={[50.73100978296274, -3.508470671164632]} zoom={22} scrollWheelZoom={false} touchZoom={false} style={{height : '476px', width : '100%'}}>
                <TileLayer data-aos="fade-left" data-aos-duration="3000" className="text-color-blue" 
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[50.73100978296274, -3.508470671164632]} icon={myIcon}>
                    <Popup className='text-lg'>
                    GISforAfrica HQ.

                    124 Pinhoe Rd, Exeter, UK
                    
                    (480) 555-0103
                    Support@gis4.africa
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
  )
}

export default Map


