import React from "react";
import picture from "../../../Assets/images/var_img.jfif";
import PageHeader from "../../Atoms/PageHeader";
import { valueAddedRetails } from "../../../Utils/data";
import ProductCard from "../../Atoms/ProductCard";
import ValueAddedProducts from "../ValueAddedProducts";
import Subscribe2 from "../../Atoms/Subscribe2";

const ValueAddedRetail = () => {
  
  return (
    <div>
      <PageHeader
        title={"Value Added Retail"}
        info={
          "GIS4Africa is keen into people’s devlopement, in order to achieve our aim to increasing soceital knowledge we have  list of our ongoing courses"
        }
        picture={picture}
      />
      <div>
        <ValueAddedProducts />
      </div>
      <div className="lg:mx-96 mx-16 my-120">
        <div className="grid lg:grid-cols-2 grid-col-1 gap-x-32 gap-y-60 ">
          {valueAddedRetails.map((valueAddedWork, id) => {
            return (
              <div key={id}>
                <ProductCard
                  title={valueAddedWork.title}
                  description={valueAddedWork.description}
                  link={valueAddedWork.link}
                />
              </div>
            );
          })
          }
        </div>
      </div>
      <Subscribe2 />
    </div>
  );
};

export default ValueAddedRetail;
