import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { CommunityImages } from "../../Utils/data";

const Slider = () => {

    
  return (
    <div className="lg:col-span-5 md:col-span-5 col-span-1 order-last lg:order-first w-full lg:w-430 flex items-center justify-center">
      <Swiper 
        modules={[Autoplay]}
        spaceBetween={10} 
        slidesPerView={1}
        autoplay= {{disableOnInteraction: false}}
      className="transition duration-700 ease-in-out lg:h-305 flex flex-row w-full">
        {CommunityImages.map((pictures, id) => {
          return (
            
                <SwiperSlide
                  className="border-solid border-5 border-S200 rounded-22 min-w-full lg:min-h-305 min-h-282 lg:min-w-full overflow-hidden "
                  key={id}
                  data-aos-offset="400"
                >
                  <img
                    src={pictures.img}
                    alt={pictures.title}
                    className="object-cover w-full h-full"
                  />
                </SwiperSlide>
           
          );
        })}
         </Swiper>
      </div>
  )
}

export default Slider