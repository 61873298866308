import React from "react";
import Button from "./Button";

const VarProductCard = ({ title, description, link, butName }) => {
  return (
    <div className="col-span-1 py-24 lg:px-64" data-aos="fade-up">
      <h2 className="font-semibold leading-42 text-P300 text-2xl">{title}</h2>
      <p className="mt-24 mb-40 text-P200 text-lg leading-27">{description}</p>
      <Button name={butName} url={link} />
    </div>
  );
};

export default VarProductCard;
