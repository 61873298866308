import React from "react";
import gis_forum from "../../Assets/images/gis_forum.webp";
import { gisForumInfo, gisForumStat } from "../../Utils/data";
import locationIcon from "../../Assets/icons/MapPinLine.svg";
import Alarm from "../../Assets/icons/alarm.svg";
import CounterUp from './CounterUp';

const Content_stat = () => {
  return (
    <div className="grid lg:grid-cols-12 grid-cols-1">
      <div className="lg:col-span-6 text-center lg:text-left col-span-1 lg:p-94 md:px-64 p-16 mt-24">
        <h2
          className="text-P500 lg:text-3xl text-xl font-semibold leading-52"
          data-aos="fade-up"
        >
          Hosting GIS Forum
        </h2>
        <p
          className="text-P200 lg:text-xl text-lg leading-36 pt-24"
          data-aos="fade-up"
        >
          We discuss recent geospatial trends, problem arising, and most
          importantly solutions to addressing these problems
        </p>
        <div className="flex flex-row lg:gap-64 gap-48 lg:justify-between md:justify-between justify-center mt-32 lg:mb-0 mb-48 lg:py-32 lg:px-48 px-16 py-16 items-center lg:rounded-2xl rounded-lg" style={{ backgroundImage: `linear-gradient(to right, rgba(231,231,234,0.5), rgba(231,231,234,0.5))`}}>
          {gisForumStat.map((stat, id) => {
            return (
              <div key={id} className="text-center">
                <p className="text-P200 lg:text-lg text-base font-medium leading-27 ">
                  {stat.title}
                </p>
                <span className="text-P500 text-xl lg:font-medium font-semibold leading-41.6">
                    <CounterUp count={stat.count} unit={stat.unit}  plus={stat.plus}/>
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="lg:col-span-6 col-span-1 flex flex-col">
        <div className="flex-1" data-aos="fade-up">
          <img
            src={gis_forum}
            alt="gis forum"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="space-y-40 py-48 lg:px-64 px-16 flex-1" style={{ backgroundImage: `linear-gradient(to right, rgba(231,231,234,0.3), rgba(231,231,234,0.3))`}}>
          <div className="flex justify-between items-center lg:pr-64 pr-0">
            <div className="flex gap-6">
              <img src={Alarm} alt="Alarm" className="w-fit h-fit" />
              <h2 data-aos="fade-up" className="text-lg text-P300 font-medium">
                Upcoming Events
              </h2>
            </div>
            <a href="https://forms.gle/hgpwL2k2kMGSsTfX8" target="_blank" rel="noopener noreferrer">
              <button className='px-32 py-12 bg-S300 rounded text-white hover:scale-95'>Register</button>
            </a>
          </div>
          {gisForumInfo.map((forumStat, id) => {
            return (
              <div key={id}>
                <h2
                  className="text-P300 lg:text-xl text-lg font-semibold"
                  data-aos="fade-up"
                >
                  {forumStat.theme}
                </h2>
                <div className="flex flex-row lg:text-lg text-base text-P300 font-medium lg:gap-40 gap-3 mt-20">
                  <span data-aos="fade-up">{forumStat.date}</span>
                  <span data-aos="fade-up">{forumStat.time}</span>
                  <div className="flex flex-row gap-5 items-center" data-aos="fade-up">
                    <img src={locationIcon} alt="location" className="w-fit h-fit" />
                    <span>{forumStat.location}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Content_stat;
