import React from 'react'

const ContactForm = () => {
  return (
    <div className='lg:order-last md:order-last order-first col-span-1'>
        <h2 className='font-semibold text-2xl text-P500 mb-32'>Leave us message</h2>
        <form className="w-inherit lg:space-y-32 space-y-16 " data-aos="fade-up" action="https://formsubmit.co/gisforafrica@gmail.com" method="post">
        <input type="hidden" name="_captcha" value="false" />
        <div>
          <label className="text-P200 lg:text-lg text-lg leading-27 font-medium">
            Name
          </label>
          <input
            type="text"
            name="Name"
            placeholder="First Lastname"
            className="border-solid border-P75 border-1 outline-none rounded text-75 text-lg text-lg h-48 py-9.5 px-12 w-full"
            required
          />
        </div>
        <div>
          <label className="text-P200 lg:text-lg text-lg leading-27 font-medium">
            Email
          </label>
          <input
            type="email"
            name='Email'
            placeholder="example@gmail.com"
            className="border-solid border-P75 border-1 outline-none rounded text-75 lg:text-lg text-lg h-48 py-9.5 px-12 w-full"
            required
          />
        </div>
        <div>
          <label className="text-P200 lg:text-lg text-lg leading-27 font-medium">
            Subject
          </label>
          <input
            type="text"
            name="Subject"
            placeholder=""
            className="border-solid border-P75 border-1 outline-none rounded text-75 lg:text-lg text-lg h-48 py-9.5 px-12 w-full"
          />
        </div>
        <textarea
            type="text"
            name='message'
            placeholder="Leave a message"
            id="ContactComment"
            className="border-solid border-P75 border-1 outline-none rounded text-75 text-lg h-135 py-9.5 px-12 w-full"
          />
        <button className="py-16 px-64 bg-P300 text-white text-lg font-medium rounded hover:scale-95 transition-transform" type='submit'>
            Send a message
        </button>
      </form>
    </div>
  )
}

export default ContactForm