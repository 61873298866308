import React from "react";
import blogSubmission from "../../Assets/images/blog_submission.jfif";

const SubmitBlog = () => {
  return (
    <div
      className="h-[420px] bg-cover bg-center text-center text-white"
      style={{ backgroundImage: `url(${blogSubmission})` }}
    >
      <div
        className="h-full flex flex-col gap-[40px] justify-center items-center"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(7,12,26,0.8), rgba(7,12,26,0.8))`,
        }}
      >
        <div className="space-y-[24px]">
          <h3 className="lg:text-[36px] text-[24px] font-bold leading-[41.6px]">
            Want to write a blog?
          </h3>
          <p className="lg:text-[24px] text-[18px] font-bold leading-[36px]">
            Would you like your blog to be featured on our page
          </p>
        </div>
        <div className="space-y-[9px]">
          <p className="text-[18px] leading-[36px] font-medium">
            Reach out to us on
          </p>
          <a
            href="https://mail.google.com/mail?view=cm&fs=1&tf=1to=gis4africa@gmail.com"
            rel="noreferrer"
            target="_blank"
            className="lg:text-[24px] text-[18px] leading-[36px] font-semibold hover:text-S300"
          >
            gisforafrica@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default SubmitBlog;
