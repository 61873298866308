import React from "react";
import Button from "./Button";

const ProductCard = ({ title, description, link }) => {
  return (
    <div
      className="col-span-1 lg:p-64 p-24 shadow-[0px_0px_32px_rgba(8,13,30,0.1)] rounded "
      data-aos="fade-up"
    >
      <h2 className="font-semibold leading-42 text-P300 text-2xl">{title}</h2>
      <p className="mt-24 mb-40 text-P200 text-lg leading-27 font-normal">
        {description}
      </p>
      <Button name="Book a Slot" url={link} />
    </div>
  );
};

export default ProductCard;
