import React from 'react'
import Artboard from '../../Assets/images/Artboard.webp'
const Content_3 = () => {
  return (
    <div className='flex flex-col items-center justify-center px-16 lg:pt-160 pt-64 '>
        <div className='lg:w-841 w-299 text-center px-13'>
            <h2 className='text-P500 lg:text-2xl text-xl leading-52 font-semibold' data-aos="fade-up" >Geospatial Institute</h2>
            <p className='lg:text-xl text-lg text-P200 font-normal lg:leading-42 leading-27 lg:w-801 w-299' data-aos="fade-up" >We believe the right technology can help change our environment and
                 bridge the gap in geospatial knowledge</p>
        </div>
        <div data-aos="fade-up" className="mt-24" >
            <img src={Artboard} alt="Artboard" className='w-full h-full' />
        </div>
    </div>
  )
}

export default Content_3