import React from 'react'
import heroImg from '../Assets/images/about_hero_img.jpg';
import { visionMissionInfo } from '../Utils/data';
import ScrollIntoView from '../Component/Atoms/ScrollView';
import SEO from './SEO'

const About = () => {
  return (
    <ScrollIntoView>
    <SEO title={'GISforAfrica | About Us'} url={'https://gisfor.africa/about'}/>
    <div>
        <div className='h-442 bg-[linear-gradient(305.82deg,_#0b132b_-75.82%,_#33CC66_179.54%)]  '>
            <div className='bg-roadmap_texture h-full w-full grid-cols-12 grid gap-14'>
                <h2 className='col-span-5 py-191 lg:px-96 px-16 text-white text-2xl font-semibold' data-aos="fade-up" >About Us</h2>
                <div className='col-span-7 h-442 lg:block hidden' data-aos="fade-up">
                    <img src={heroImg}  alt="hero" className="object-cover h-full" />
                </div>
            </div>
        </div>
        <div className='font-normal lg:text-xl text-lg leading-36 '>
            <article data-aos="fade-up" className='py-92 lg:px-202 px-16 leading-36 space-y-40 lg:text-left lg:text-xl'>
            <p>GISforAfrica is an exciting institution for development and growth. 
                The GISforAfrica Community is made up of individuals, groups of individuals, companies, government agencies and organizations from 
                different geographic locations and of different disciplines, ages, languages, ideas, genders and drives all united under the umbrella of 
                impacting GIS in Africa for Africa communities therefore propelling socio-economic development.</p>

            <p>GISforAfrica offers a choice of products that suits your personal and 
                organizational preferences as well as a variety of opportunities to get involved in community activities courses and 
                social economic events too. </p>
            </article>
        </div>
        <div className='lg:mt-150 mt-24 space-y-68 mb-128 px-16 lg:px-68 flex flex-col lg:gap-0 gap-y-128'>
            {visionMissionInfo.map((info, id) => {
                return (
                    <div key={id} className="flex lg:flex-row flex-col gap-43">
                        <div className='flex-1 h-382 bg-P500 lg:px-96 px-16 lg:py-64 py-24 text-white space-y-32 hover:scale-95 transition-transform lg:order-first order-last'>
                            <h1 className='font-semibold text-xl lg:text-2xl leading-41.6' data-aos="fade-up">{info.title}</h1>
                            <p className='font-normal text-lg lg:text-xl leading-36' data-aos="fade-up">{info.text}</p>
                        </div>
                        <div className='flex-1 flex items-center justify-center'data-aos="fade-up">
                            <img src={info.vector} alt={info.title} />
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
    </ScrollIntoView>
  )
}

export default About
