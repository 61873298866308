import React from 'react'
import picture from '../../../Assets/images/career_img.jpg'
import PageHeader from '../../Atoms/PageHeader';
import { careerDevCourses } from '../../../Utils/data';
import ProductCard from '../../Atoms/ProductCard';
import Subscribe2 from '../../Atoms/Subscribe2';

const CareerDev = () => {
  return (
    <div>
      <PageHeader 
      title={"Career development"} 
      info={"GIS4Africa is keen into people’s devlopement, in order to achieve our aim to increasing soceital knowledge we have  list of our ongoing courses"}
      picture={picture} 
      />
      <div className='lg:px-96 px-16 py-120'>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-x-32 gap-y-60 '>
        {careerDevCourses.map((course, id)=> {
          return (
            <div key={id}>
              <ProductCard title={course.title} description={course.description} link={course.link}/>
            </div>
          )
        })}
        </div>
      </div>
      <Subscribe2 />
    </div>
  )
}

export default CareerDev
