import { Link } from "react-router-dom";
import logo from "../../Assets/logo/gisforafrica_logo.webp";
import { NavLink } from "react-router-dom";
import navigate_icon from "../../Assets/icons/Vector.svg";
import MenuIcon from "../../Assets/icons/Menu_icon.svg";
import CloseIcon from "../../Assets/icons/Close_icon.svg";
import { useState } from "react";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const closeMenu = () => {
    setShowMenu(false);
    setDropDown(false);
  };
  return (
    <div className=" relative grid grid-cols-12 lg:px-96 px-16 z-50">
      <div className="col-span-12 flex justify-between lg:h-90 h-56 items-center relative">
        <div className="logo flex justify-center items-center lg:w-180 w-123 ">
          <Link to="/" onClick={closeMenu}>
            <img src={logo} alt="milsat logo" />
          </Link>
        </div>
        <div className="lg:hidden" onClick={() => setShowMenu(!showMenu)}>
          <img
            src={showMenu ? CloseIcon : MenuIcon}
            alt="menu icon"
            className="w-24 h-24"
          />
        </div>
        <nav
          className={`
          transition-all delay-200 ease-in-out
            ${showMenu
              ? "absolute bg-white h-screen w-screen -right-4 top-full pt-24 transition delay-150 ease-in-out"
              : "lg:block hidden transition delay-150 ease-in-out "}
          `}
        >
          <ul className="flex justify-between lg:items-center lg:flex-row flex-col px-24 bg-white lg:gap-40 gap-24 font-normal text-lg text-P200">
            <NavLink
              to=""
              end
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive
                  ? "border-solid border-S200 border-b-2 lg:w-inherit max-w-min"
                  : ""
              }
            >
              <li>Home</li>
            </NavLink>
            <div
              className="flex gap-8.5 lg:justify-start justify-between lg:py-6 group"
              onMouseEnter={() => {
                setDropDown(true);
              }}
              onMouseLeave={() => {
                setDropDown(false);
              }}
            >
              {" "}
              <NavLink
                to="products/event"
                className={({ isActive }) =>
                  isActive
                    ? "  lg:border-solid lg:border-S200 lg:border-b-2 lg:w-inherit max-w-min"
                    : ""
                }
              >
                <li className="relative leading-7">
                  Products
                  <ul
                    className={` ${
                      dropDown ? "block" : "hidden"
                    } lg:absolute relative w-263 lg:mt-21.18 mt-16 lg:-right-2/4 lg:p-16   bg-white lg:shadow-[0px_4px_32px_rgba(7,12,26,0.2)] rounded-lg lg:group-hover:block lg:hidden`}
                  >
                    <NavLink
                      to="products/event"
                      onClick={closeMenu}
                      className={`transition-transform ${({ isActive }) =>
                        isActive
                          ? "  lg:border-solid lg:border-S200 lg:border-b-2 lg:w-inherit max-w-min"
                          : ""}`}
                    >
                      <li className="py-8 pl-16 hover:bg-S50 rounded-lg">
                        Event
                      </li>
                    </NavLink>
                    <NavLink
                      to="products/careerdev"
                      onClick={closeMenu}
                      className={({ isActive }) =>
                        isActive
                          ? "  lg:border-solid lg:border-S200 lg:border-b-2 lg:w-inherit max-w-min"
                          : ""
                      }
                    >
                      <li className="py-8 pl-16 hover:bg-S50 rounded-lg">
                        Career Development
                      </li>
                    </NavLink>
                    <NavLink
                      to="products/valueaddedretail"
                      onClick={closeMenu}
                      className={({ isActive }) =>
                        isActive
                          ? "  lg:border-solid lg:border-S200 lg:border-b-2 lg:w-inherit max-w-min"
                          : ""
                      }
                    >
                      <li className="py-8 pl-16 hover:bg-S50 rounded-lg">
                        Value Added Retail
                      </li>
                    </NavLink>
                  </ul>
                </li>
              </NavLink>
              <div
                className="lg:flex lg:justify-center"
                onClick={() => {
                  setDropDown(!dropDown);
                }}
              >
                <img
                  src={navigate_icon}
                  alt="navigate_icon"
                  className={
                    dropDown
                      ? "rotate-180 transition-transform text-P200 "
                      : "transition-transform text-P200 "
                  }
                />
              </div>
            </div>
            <NavLink
              to="blog"
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive
                  ? "  border-solid border-S200 border-b-2 lg:w-inherit max-w-min"
                  : ""
              }
            >
              <li>Blog</li>
            </NavLink>
            <NavLink
              to="about"
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive
                  ? "  border-solid border-S200 border-b-2 lg:w-inherit max-w-max"
                  : ""
              }
            >
              <li>About Us</li>
            </NavLink>
            <NavLink
              to="contact"
              onClick={closeMenu}
              className={({ isActive }) =>
                isActive
                  ? "  border-solid border-S200 border-b-2 lg:w-inherit max-w-max"
                  : ""
              }
            >
              <li>Contact Us</li>
            </NavLink>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
