import React from 'react'

const Button = ({name, url}) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
        <button className='px-16 py-12 bg-S300 rounded text-white hover:scale-95'>{name}</button>
    </a>
  )
}

export default Button