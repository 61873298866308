import React, {useState} from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const CounterUp = ({ count, unit, plus }) => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div>
         <ScrollTrigger onEnter={() => setCounterOn(true)}>
        <div>
          {plus}
          {counterOn && (
            <CountUp start={0} end={count} duration={2} delay={0} />
          )}
          {unit}
        </div>  
      </ScrollTrigger>
    </div>
  );
};

export default CounterUp;
