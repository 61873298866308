import React from "react";
import Subscribe from "./Subscribe";

const Content_4 = () => {
  return (
    <div className="relative">
      <div className="lg:h-593 h-282 bg-[linear-gradient(278.3deg,_#0B132B_-30.78%,_#33CC66_150.33%)] ">
        <div className="bg-sub_texture w-full h-full">
          <span className=" flex items-center justify-center text-white lg:text-3xl text-xl lg:pt-123 lg:pt-96 pt-48">
            <h2 className="lg:w-779 w-278 text-center lg:leading-52 leading-27">
              African’s existing network of people and products
            </h2>
          </span>
        </div>
      </div>
      <div className="lg:h-465 h-465 flex items-center justify-center">
        <Subscribe
        mainContainerStyle ="lg:p-48 m-16 p-24 lg:w-657 w-299 bg-white absolute lg:top-20rem top-8rem shadow-[0px_4px_32px_rgba(7,12,26,0.2)] rounded "
        containerStyle="flex flex-col items-center justify-center space-y-10"
        hStyle="text-center text-P500 font-medium text-xl"
        pStyle="text-center font-normal text-P200 lg:text-lg lg:leading-27 leading-19.6 lg:px-0 px-16 mb-22 mt-24 "
        formStyle="w-inherit"
        buttonStyle="py-16 px-64 bg-P300 text-white text-lg font-medium rounded hover:scale-95 transition-transform"
        />
      </div>
    </div>
  );
};

export default Content_4;
