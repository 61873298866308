import React from 'react'


const PageHeader = ({title, info, picture }) => {
  return (
    <div className='h-384 bg-cover bg-center' style={{ backgroundImage: `url(${picture})`}}>
    <div className="h-full"style={{ backgroundImage: `linear-gradient(to right, rgba(52,59,79,0.8), rgba(52,59,79,0.8))`}}>
      <div className='bg-sub_texture h-full w-full py-101 lg:px-96 px-16 py-64 text-white'>
          <span className='font-medium text-lg leading-27 border-solid border-b-2 pb-5 border-white'>Product</span>
          <h2 className='font-medium text-3xl leading-52 lg:mt-11 mt-2'>{title}</h2>
          <p className='font-medium text-lg leading-27 lg:mt-48 mt-24 w-11/12 lg:w-612 h-81'>{info}</p>
      </div>
    </div>
  </div>
  )
}
export default PageHeader