import React from "react";
import { BlogsContents } from "../Utils/data";
import { useParams } from "react-router-dom";
import ScrollIntoView from "../Component/Atoms/ScrollView";
import SubmitBlog from "../Component/Atoms/SubmitBlog";

const BlogDetails = () => {
  const { title } = useParams();
  return (
    <ScrollIntoView>
      <div>
        {BlogsContents.map((blog, id) => {
          if (blog.title === title) {
            return (
              <div key={id}>
                <section>
                  <div
                    className="h-[360px] bg-cover bg-center "
                    style={{ backgroundImage: `url(${blog.picture})` }}
                  >
                    <div
                      className="h-full flex lg:justify-center lg:items-center lg:px-[0px] lg:py-[0px] py-[18%] px-[16px]"
                      style={{
                        backgroundImage: `linear-gradient(to right, rgba(7,12,26,0.8), rgba(7,12,26,0.6))`,
                      }}
                    >
                      <div className="lg:w-[65%] w-full">
                        <div className="">
                          <h2 className="font-semibold lg:text-[57px] text-[24px] text-white">
                            {blog.title}
                          </h2>
                          <div className="flex lg:flex-row flex-col lg:items-center lg:gap-[8px] gap-[32px] text-white lg:mt-[23px] mt-[16px]">
                            <div className="text-[14px] flex flex-row items-center gap-[9.5px]  leading-[19.6px]">
                              <span>{blog.date}</span>
                              <svg
                                width="4"
                                height="4"
                                viewBox="0 0 4 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="2" cy="2" r="2" fill="white" />
                              </svg>
                              <span>{blog.Author_full_name}</span>
                            </div>
                            <div className="flex flex-row items-center gap-[9.5px] lg:p-[14px] p-[10.5px] lg:w-[13%] w-[35%] border-2 rounded-full">
                              <span>
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.75 6C15.5985 6 12.1125 6.02175 9 7.506C5.8875 6.02175 2.4015 6 2.25 6C2.05109 6 1.86032 6.07902 1.71967 6.21967C1.57902 6.36032 1.5 6.55109 1.5 6.75V14.1622C1.5 14.2629 1.52025 14.3625 1.55955 14.4551C1.59885 14.5478 1.65639 14.6316 1.72875 14.7015C1.875 14.8425 2.08875 14.9303 2.2755 14.9115L2.37075 14.9108C2.883 14.9108 5.59275 14.9843 8.68275 16.4295C8.69475 16.4355 8.70825 16.4332 8.72025 16.4377C8.8095 16.4745 8.90325 16.5 9 16.5C9.09675 16.5 9.1905 16.4745 9.2805 16.4377C9.2925 16.4332 9.306 16.4355 9.318 16.4295C12.408 14.9835 15.1178 14.9108 15.63 14.9108L15.7252 14.9115C15.9037 14.9303 16.125 14.8425 16.272 14.7015C16.4175 14.5597 16.5 14.3647 16.5 14.1622V6.75C16.5 6.55109 16.421 6.36032 16.2803 6.21967C16.1397 6.07902 15.9489 6 15.75 6ZM3 7.53675C4.11375 7.62 6.28575 7.89675 8.25 8.80575V14.6122C6 13.731 4.0575 13.4917 3 13.4302V7.53675ZM15 13.4302C13.9425 13.4917 12 13.731 9.75 14.6122V8.80575C11.7142 7.89675 13.8862 7.62 15 7.53675V13.4302Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M9 6C10.2426 6 11.25 4.99264 11.25 3.75C11.25 2.50736 10.2426 1.5 9 1.5C7.75736 1.5 6.75 2.50736 6.75 3.75C6.75 4.99264 7.75736 6 9 6Z"
                                    fill="white"
                                  />
                                </svg>
                              </span>
                              <span className="lg:text-[14px] text-[10px] font-bold">{blog.read_time} min read</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="w-full my-[82px] ">
                  <div className=" w-full flex justify-center items-center  font-normal ">
                    <article className="block lg:w-[65%] lg:px-[0px] px-[16px]">
                      {blog.article.map((paragraph, id) => {
                        return (
                          <p key={id} className="mt-[24px] text-[18px] leading-[27px]">
                            {paragraph.p}
                          </p>
                        );
                      })}
                    </article>
                  </div>
                </section>
              </div>
            );
          }
          return "";
        })}
      </div>
      <SubmitBlog />
    </ScrollIntoView>
  );
};

export default BlogDetails;
