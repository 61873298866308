import React, {useState} from 'react'
import ScrollIntoView from '../Component/Atoms/ScrollView'
import blogBg from "../Assets/images/blog_texture.jpg";
import search_icon from "../Assets/icons/search_icon.svg";
import SEO from './SEO'
import BlogPosts from '../Component/Organisms/BlogPosts';
import {BlogsContents} from '../Utils/data'
import { Link } from 'react-router-dom';

const Blog = () => {
  const [filteredBlogs, setFilteredBlogs] = useState(BlogsContents)
  const [suggestionBox, setSuggestionBox] = useState(false)

  const filterBySearch = (event) => {
    const query = event.target.value
    var updatedList = [...BlogsContents]
    if (query !== ""){
      setSuggestionBox(true)
      updatedList = updatedList.filter((blog) => {
        return blog.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      })
    } else{
      setSuggestionBox(false)
    }
    setFilteredBlogs(updatedList)
  }
  return (
    <ScrollIntoView>
      <SEO title={'GISforAfrica | Blog '} url={'https://gisfor.africa/blog'}/>
      <section>
        <div
          className="h-384 bg-cover bg-center "
          style={{ backgroundImage: `url(${blogBg})` }}
        >
          <div className='lg:px-96 px-16'>
            <div className=" w-full text-center pt-[163px] lg:px-96 px-16">
                <h2 className="font-medium text-3xl leading-52 text-white ">Blog</h2>
            </div>
            <div className='flex lg:justify-end justify-center mt-[70px] '>
              <div className='flex flex-col' onMouseLeave={()=> {setSuggestionBox(false)}}>
                <div className='flex justify-between py-[11px] px-[12px] bg-Gray-400 w-[294px] rounded '>
                  <input type='text'placeholder='Search for blog' className="outline-none rounded bg-Gray-400 text-[13px] text-Gray-600" onChange={filterBySearch} />
                  <img src={search_icon} alt='search'/>
                </div>
                {suggestionBox ?
                <ul className='flex flex-col py-[8px] mt-[4px] text-[13px] rounded-b text-black bg-white w-[294px] shadow-[0px_4px_32px_rgba(7,12,26,0.2)]'>
                  { 
                    filteredBlogs.map((blog, id) => {
                      return <Link to={`${blog.title}`}><li key={id} className='p-[8px] mx-[4px] rounded hover:bg-S50'>{blog.title}</li></Link>
                    }) 
                  }
                </ul>: "" }
              </div>
            </div>
          </div>
          </div>
      </section>
      <section className='my-[128px] lg:px-[0px] px-[16px] w-full flex justify-center'>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-[24px]">
        {BlogsContents.map((blog, id) => {
          return <BlogPosts key={id} Title={blog.title} Date={blog.date} Author_first_Name={blog.Author_full_name} Summary={blog.summary} link={blog.title} />
        })}
      </div>
      </section>
    </ScrollIntoView>
  )
}

export default Blog
