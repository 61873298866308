import facebook from '../Assets/icons/Facebook (icon — Colour).svg'
import instagram from '../Assets/icons/Instagram (icon — Colour).svg'
import Twitter from '../Assets/icons/Twitter (icon — Colour).svg'

import missionIcon from '../Assets/icons/mission_vector.svg'
import visionIcon from '../Assets/icons/vision_vector.svg'

import Partner_1 from '../Assets/logo/Partner_1.webp'
import Partner_2 from '../Assets/logo/Partner_2.webp'
import Partner_3 from '../Assets/logo/Partner_3.webp'
import Partner_4 from '../Assets/logo/Partner_4.png'


import Adedokun_faruk from '../Assets/images/team members/Adedokun Faruk.jpg'
import Ademoyero_Victor from '../Assets/images/team members/Ademoyero Victor.jpg'
import Ogheneochuko_Olemu from '../Assets/images/team members/Ogheneochuko Olemu.jpeg'
import Ogungbemi_ezekiel from '../Assets/images/team members/Ogungbemi Ezekiel.jpg'
import Uche_michael from '../Assets/images/team members/uche michael.jpg'
import Okikiri_favour from '../Assets/images/team members/favour okikiri.jpg'
import Ilegbedion_matthew from '../Assets/images/team members/Matthew Ilegbedion.png';
import Akintola_Mercy from '../Assets/images/team members/Akintola Mercy.jpeg'
import Olanrewaju_Michael from '../Assets/images/team members/Olanrewaju Michael.jpeg'


import picture_2 from '../Assets/images/people_2.webp';
import picture_3 from '../Assets/images/people_3.webp';
import picture_4 from '../Assets/images/people_4.webp';
import picture_5 from '../Assets/images/people_5.webp';


import agriculture from '../Assets/images/blogs/agriculture.jpg'


export const socialMediaLinks = [
    {
        "id":"Twitter",
        "url":"https://twitter.com/GISforAfrica/",
        "icon": Twitter
    },
    {
        "id":"facebook",
        "url":"https://web.facebook.com/GISforAfrica/",
        "icon": facebook
    },
    {
        "id":"instagram",
        "url":"https://www.instagram.com/gisforafrica/",
        "icon": instagram
    },
]

export const contactInfo = [
    {
        "address1": "124 Pinhoe Rd, Exeter, UK",
        "address2": "No 2, Shalom Close, Ogungbade, Ibadan, Nigeria",
        "phone": "(480) 555-0103",
        "email": "Support@gis4.africa"
    }
]

export const footerlinks = [
    {
        'id': 1,
        'name': "COMPANY",
        'linkContent': [
            {
                'link_name': 'Products',
                'link': '/products/event'
            },
            {
                'link_name': 'About Us',
                'link': '/about'
            },
            {
                'link_name': 'Contact Us',
                'link': '/contact'
            }
        ] 
    },
    {
        'id': 2,
        'name': "OUR TEAM",
        'linkContent': [
            {
                'link_name': 'Personnel',
                'link': '/personnel'
            },
            {
                'link_name': 'Career',
                'link': '/products/career'
            }
        ] 
    },
    {
        'id': 3,
        'name': "RESOURCES",
        'linkContent': [
            {
                'link_name': 'Blog',
                'link': '/blog'
            },
            {
                'link_name': 'Events',
                'link': 'products/events'
            }
        ] 
    }
]

export const PartnersData = [
    {
        'name': 'Geo-envtech',
        'logo': Partner_1
    },
    {
        'name': 'nodes',
        'logo': Partner_2
    },
    {
        'name': 'GISFORAFRICA Community',
        'logo': Partner_3
    },
    {
        'name': 'efrain map',
        'logo': Partner_4
    }
]

export const gisForumStat = [
    {
        "title": "Listeners",
        "count": 5,
        "unit": "K",
        "plus": "+"
    },
    {
        "title": "GIS Forum",
        "count": 7,
        "unit": "",
        "plus": ""
    },
    {
        "title": "Countries",
        "count": 79,
        "unit": "",
        "plus": ""
    }
]
export const gisForumInfo = [
    {
        "theme": "Nexus GIS Professionals",
        "date": "7 June 2023",
        "time": "12pm WAT",
        "location": "Google Meet"
    },
    {
        "theme": "GIS Short Courses",
        "date": "Classes start from May, 2023",
        "time": "",
        "location": "Online"
    },
]

export const visionMissionInfo = [
    {
        "title": "MISSION STATEMENT",
        "text": "To implement a formidable education and production network across Africa through standard procedures or guidelines for structural and infrastructural development and growth. ",
        "vector": missionIcon
    },
    {
        "title": "VISION STATEMENT",
        "text": "People engage with GISforAfrica to build a better environment with friends and family while exploring resources in the world to share and express what matters to them.",
        "vector": visionIcon
    }
]

export const careerDevCourses = [
    {
        'title': "Nexus GIS Professionals",
        'description': "Take your career to new heights with adept geospatial intelligence and mapping tools. Nexus GIS Professional gives you a competitive edge in the industry and harness the power of cutting-edge technology. Elevate your skills and become a GIS expert. Join Nexus GIS Professional today!",
        'link': "https://forms.gle/hgpwL2k2kMGSsTfX8"      
    },
    {
        'title': "Entrepreneurs Acceleration and Mentorship Program",
        'description': "Provides supports and empowers Africa’s entrepreneurs to scale their businesses to the next level.",
        'link': "https://forms.gle/qPxKFZXdnNh8iQxJA"      
    },
    {
        'title': "Nexus GIS Diploma",
        'description': "In Nexus GIS Diploma you would have access to our class content, our trainers and also go through stages of GIS project execution. Subscription comes with Charges.",
        'link': "https://forms.gle/jVCsqeHMt7zKVgPe7"
    },
    {
        'title': "Xplore",
        'description': "This is a joint exploration between researcher and GISforAfrica with the aim of creating a business venture.",
        'link': "https://forms.gle/vaGHFvBGGAa1SBzZ6 "
    },
    {
        'title': "Robotic Car Course",
        'description': "One of our engineering courses for exploration of micro-automobiles and automations.",
        'link': "https://forms.gle/JujkuuhhZWr8R5oi6"
    },
    {
        'title': "Og Field Activities",
        'description': "Community. Members are engaged in skills acquisition and talent exhibition in the greenspace.",
        'link': "https://forms.gle/hfyb38Yni6Sk7ETEA"
    }
    
]

export const eventsDetails = [
    {
        'title': "GIS Forum",
        'description': "This is a confluence of GIS community where there are discussions around ideas, and collaborations to solve problems with GIS.",
        'link': "https://forms.gle/ZzTpaSKR96Fw9w5f9"      
    },
    {
        'title': "Challenge",
        'description': "Often a week long celebration of talents, successes and achievements. Furthermore somewhat of planning for the new year.",
        'link': "https://forms.gle/ec96g9DtLgA5mNp77"
    },
    {
        'title': "CityTalk",
        'description': "In this event, GISforAfrica share important ways GIS can help improve City Lives and Livelihoods.",
        'link': "https://forms.gle/dhLMesf1AkYCjjbk9"
    },
    {
        'title': "GDP Meetings",
        'description': "GISforAfrica development project officers meet to apply  processes, methods, skills, knowledge and experiences to achieve project’s weekly, month and general objectives",
        'link': "https://forms.gle/HoZaAAJvh7ykby6S7"
    },
    {
        'title': "Super 8",
        'description': "The super IV is a platform for clubs, players and talents to thrive for domestic and international acclaims and success while developing local and regional markets.",
        'link': "https://forms.gle/HWPtfk5HgDWLrcJU9"
    }
]


export const valueAddedRetails = [
    {
        'title': "Fashion",
        'description': "Discover the latest collections of sneakers, handbags, and ready to wear for women and men. (exclusive to Nigeria)",
        'link': "https://forms.gle/8bMwTpDqVxmQWziy5"      
    },
    {
        'title': "Bricklaying",
        'description': "laying bricks for foundations, buildings or structures. Also repair walls, partitions, arches and other structural defects",
        'link': "https://forms.gle/g3JSEknPi2QjPRYK7"
    },
    {
        'title': "Electrical Engineering",
        'description': "wel design and implement electronic systems  with practical application of current technologies",
        'link': "https://forms.gle/DHzpATEukp3gNZ5Z8"
    },
    {
        'title': "Furniture",
        'description': "Buy home office furniture & more (exclusive to Nigeria)",
        'link': "https://forms.gle/r54NpdU1wt2ZpcX38"
    }
]


export const TeamInfo = [
    {
        "name": "Ogheneochuko Olemu",
        "position": "Founder/Project Governor",
        "picture": Ogheneochuko_Olemu, 
        "Twitter_handle": "https://twitter.com/olemuochuko",
        "LinkedIn": "https://www.linkedin.com/in/olemu-ogheneochuko-6b632a66/"
    },
    {
        "name": " Ogungbemi Ezekiel",
        "position": "Executive Secretary",
        "picture": Ogungbemi_ezekiel, 
        "Twitter_handle": "https://twitter.com/onesimus_GGA",
        "LinkedIn": "https://www.linkedin.com/in/ezekiel-ogungbemi-98a98a1a9/"
    },
    {
        "name": "Akintola Mercy",
        "position": "Community Builder",
        "picture": Akintola_Mercy, 
        "Twitter_handle": "https://twitter.com/Mercixs20",
        "LinkedIn": "https://www.linkedin.com/in/akintola-mercy/"
    },
    {
        "name": " Olanrewaju Michael",
        "position": "Product Designer",
        "picture": Olanrewaju_Michael, 
        "Twitter_handle": "https://twitter.com/Teemi_classic",
        "LinkedIn": "https://www.linkedin.com/in/olanrewajumichael/"
    },
    {
        "name": "Ademoyero Victor",
        "position": "GIS Manager",
        "picture": Ademoyero_Victor, 
        "Twitter_handle": "https://twitter.com/vickystickz",
        "LinkedIn": "https://www.linkedin.com/victorademoyero"
    },
    {
        "name": "Okikiri Favour",
        "position": "Spatial Data Scientist",
        "picture": Okikiri_favour, 
        "Twitter_handle": "https://twitter.com/Okikiri_Favour",
        "LinkedIn": "https://www.linkedin.com/in/favour-okikiri-982a9b174/"
    },
    {
        "name": "Ilegbedion Matthew ",
        "position": "BSDB Governor ",
        "picture": Ilegbedion_matthew, 
        "Twitter_handle": "https://twitter.com/MatthewRevived",
        "LinkedIn": "https://www.linkedin.com/in/matthew-ilegbedion-5300641a4/"
    },
    {
        "name": "Adedokun Faruk",
        "position": "Super IV Manager",
        "picture": Adedokun_faruk, 
        "Twitter_handle":"https://twitter.com/FarukAdedokun11",
        "LinkedIn": "#"
    },
    {
        "name": "Uche Michael",
        "position": "Head E-Marketing",
        "picture": Uche_michael, 
        "Twitter_handle": "https://twitter.com/Michemwax",
        "LinkedIn": "https://www.linkedin.com/in/uche-unegbu-b2094b157/"
    }
]

export const CommunityImages = [
    {
        img: picture_2,
        title: "community"
    },
    {
        img: picture_3,
        title: "Photography 1"
    },
    {
        img: picture_4,
        title: "Photography 2"
    },
    {
        img: picture_5,
        title: "community work"
    }
]


export const BlogsContents = [
    {
        id: 0,
        title: "Improving rural food security with GIS Technologies",
        date: "11 Febuary, 2023",
        Author_full_name: "Elisa Gottlieb",
        picture: agriculture,
        summary: "Global food demand has skyrocketed and is expected to exceed 50% by 2050. However, there are growing concerns that agricultural food production systems will be unable to meet high demand, particularly in developing countries, leading to an increase in rural food insecurity. The most common causes of food insecurity in these areas are inefficiency in food production systems and a lack of data on farmers. How to best facilitate food production to markets without compromising community accessibility and ensuring that policymakers make spatial-based decisions?...",
        article: [
            {p: "Global food demand has skyrocketed and is expected to exceed 50% by 2050. However, there are growing concerns that agricultural food production systems will be unable to meet high demand, particularly in developing countries, leading to an increase in rural food insecurity. The most common causes of food insecurity in these areas are inefficiency in food production systems and a lack of data on farmers. How to best facilitate food production to markets without compromising community accessibility and ensuring that policymakers make spatial-based decisions?",
            },
            {p: "In Africa, the majority of the food production is rural-based, dominated by smallholder and subsistence farmers. With the need to increase food productivity and sustainability, we need to enhance and empower the smallholders with practicable information on resilient farming that enables them not only to make evidence-informed decisions but also to implement them in activities.",
            },
            {p:"Sustainable production approaches that support production efficiency enhancement and better agronomic practices are required in efforts to transform conventional farming and often inefficient traditional subsistence production practices. Henceforth, these innovative initiatives would necessitate the use of complete and accurate, up-to-date datasets with spatial and non-spatial aspects, as well as the implementation of advanced GIS technologies capable of capturing and integrating spatial, economic, demographic, and environmental data in agriculture"
            },
            {p:"GIS can be used to observe the geographic links and factors that endanger food from production to consumption. For starters, it will allow us to identify and map farmers in rural areas, and those in remote areas will be able to collect data using remote sensing. This will allow countries to calculate their output and anticipate potential threats over time. Spatial data collected from production can be used to identify suitable markets for each farmer."
            },
            {p:"With spatial data available, it will invite investors to collaborate with the farmers to add value to their produce; this will lead to developing the rural areas and increase in food varieties while reducing food waste.Furthermore, geospatial analysis of food availability will be possible, providing insights into food deserts."
            },
            {p:"The GIS functionalities such as 3D maps allow communities and stakeholders in the sector to plan for development for sustainable and consistent production, such as dams around perennial flooding streams to prevent long drought periods."
            },
            {p:"Geospatial technologies and agricultural data help to accelerate agricultural transformation and long-term rural development in order to eradicate poverty, hunger, and malnutrition. GIS represents actionable data for farmers, communities and decision makers"
            }
        ],
        link: "/",
        read_time: 4
    }
]