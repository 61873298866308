import React from 'react'
import lottie from "lottie-web/build/player/lottie_light";
import animate from '../../Assets/Animation/Animate.mp4'
import animationMobileData from '../../Assets/Animation/Mobile.json'
const Animation = () => {
  React.useEffect(() => {
    const instance = lottie.loadAnimation({
       container: document.querySelector("#animationMobile"),
       animationData: animationMobileData,
       renderer: "svg",
       loop: true,
       autoplay: true,
     });
     return () => instance.destroy();
   }, []);

  return (
    <div className='lg:min-h-[15%]'>
     <div className='lg:h-[15%] lg:block h-screen w-full hidden md:block'>
      <video autoPlay loop muted className='object-cover min-w-screen h-[98%]' src={animate} />
    </div>
    <div id="animationMobile"  className='lg:hidden md:hidden block'>
    </div>
    </div>
  )
} 


export default Animation