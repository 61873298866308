import React from "react";
import picture from "../../../Assets/images/event_img.jfif";
import PageHeader from "../../Atoms/PageHeader";
import { eventsDetails } from "../../../Utils/data";
import ProductCard from "../../Atoms/ProductCard";
import Subscribe2 from "../../Atoms/Subscribe2";

const Event = () => {
  return (
    <div>
      <PageHeader title={"Event"} info={""} picture={picture} />
      <div className="lg:mx-96 mx-16 my-120">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-32 gap-y-60 ">
          {eventsDetails.map((event, id) => {
            return (
              <div key={id}>
                <ProductCard
                  title={event.title}
                  description={event.description}
                  link={event.link}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Subscribe2 />
    </div>
  );
};

export default Event;
