import React, {useEffect} from 'react'
import { Routes, Route } from "react-router-dom";
import Footer from "./Component/Organisms/Footer";
import Header from "./Component/Organisms/Header";
import CareerDev from "./Component/Organisms/Products/CareerDev";
import Event from "./Component/Organisms/Products/Event";
import ValueAddedRetail from "./Component/Organisms/Products/ValueAddedRetail";
import About from "./Pages/About";
import Blog from "./Pages/Blog";
import LandingPage from "./Pages/LandingPage";
import Products from "./Pages/Products";
import BlogDetails from './Pages/BlogDetails';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Personnel from './Pages/Personnel';
import ErrorPage from './Pages/ErrorPage';
import ContactUs from './Pages/ContactUs';

function App() {


  useEffect(() => {
    AOS.init({
        duration: 600,
        startEvent: 'DOMContentLoaded',
        once: true,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView({
        behavior: 'smooth'
    }, 500)

}, []);

  return (
    <div className="relative">
       <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="products" element={<Products />}>
          <Route path="event" element={<Event />} />
          <Route path="careerdev" element={<CareerDev />} />
          <Route path="valueaddedretail" element={<ValueAddedRetail />} />
        </Route>
        <Route path="about" element={<About />} />
        <Route path="blog" element={<Blog />} />
        <Route path='/blog/:title' element={<BlogDetails />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="personnel" element={<Personnel />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />  
    </div>
  );
}

export default App;
