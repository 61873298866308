import React from 'react'
import { PartnersData } from '../../Utils/data'

const Partners = () => {
  return (
    <div className='lg:h-392 p-64 flex justify-center items-center' data-aos="fade-up" >
        <div className='border-solid border-2 border-P50 flex lg:flex-row md:flex-row flex-col lg:gap-48 gap-8 py-17 lg:px-48 px-16 items-center rounded'>
            <h2 className=' text-P200 lg:text-xl text-base leading-36 font-medium'>Our Partners</h2>
            <div className='flex flex-row lg:gap-37.06 gap-16'>
                {PartnersData.map((partner, id) => {
                    return(
                        <div className='lg:w-[101px] lg:h-[82px] w-35 h-29'>
                            <img key={id} src={partner.logo} alt={partner.name} className="lg:w-full lg:h-full w-35 h-29 object-fit" />
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default Partners