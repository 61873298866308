import React from "react";
import Slider from "./Slider";

const Content_2 = () => {
  return (
    <div className="bg-P50 ">
    <div className="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 lg:gap- gap-y-48 lg:px-96 md:px-64 px-16 lg:py-80 py-64 lg:gap-64 md:gap-64" style={{ backgroundImage: `linear-gradient(to right, rgba(256,256,256,0.3), rgba(256,256,256,0.3))`}}>
      <Slider />
      <div className="lg:col-span-7 md:col-span-7 col-span-1 space-y-40 flex flex-col px-4">
        <h2
          className="font-semibold lg:text-3xl text-xl text-P500 lg:leading-51.6 leading-36"
          
        >
          Empowering diverse communities with Geospatial technology
        </h2>
        <p
          className="font-normal text-P200 lg:text-xl text-lg lg:leading-36 leading-27"
          data-aos="fade-up"
        >
          The GISforAfrica Community is made up of individuals, groups of
          individuals, companies, government agencies and organizations from
          different geographic locations and of different disciplines, ages,
          languages, ideas, genders and drives all united under the umbrella of
          proffering GIS oriented solutions in Africa.
        </p>
      </div>
    </div>
    </div>
  );
};

export default Content_2;
