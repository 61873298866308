import React from "react";
import twitter from "../../Assets/icons/twitters.svg";
import linkedin from "../../Assets/icons/linkedins.svg";

const TeamCard = ({ position, name, twitter_link, linkedin_link, picture }) => {
  return (
    <div className="lg:w-395 w-342 border-2 border-solid border-P50  rounded">
      <div className="w-inherit lg:h-430 h-340">
        <img
          src={picture}
          alt="team member"
          className="object-cover h-full w-full"
        />
      </div>
      <div className="flex flex-col justify-center items-center w-inherit py-24 ">
        <h2 className="pb-2 font-semibold text-xl leading-36 text-P300">
          {name}
        </h2>
        <span className="mb-12 font-medium text-lg text-P200">{position}</span>
        <div className="flex flex-row gap-14">
          <a href={twitter_link} target="_blank" rel="noopener noreferrer">
            <span className="bg-P75 w-24 h-24 flex items-center justify-center rounded-full hover:bg-S100 transition-tranform">
              <img src={twitter} alt="twitter" />
            </span>
          </a>
          <a href={linkedin_link} target="_blank" rel="noopener noreferrer">
            <span className="bg-P75 w-24 h-24 flex items-center justify-center rounded-full hover:bg-S100 transition-tranform">
              <img src={linkedin} alt="linkedin" />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
