/**** External Imports *****/
import { Outlet } from 'react-router-dom'
import ScrollIntoView from '../Component/Atoms/ScrollView'
import SEO from './SEO'

const Products = () => {
  return (
    <div>
      <ScrollIntoView>
       <SEO title={'GISforAfrica | Events'} url={'https://gisfor.africa/products/event'}/>
       <SEO title={'GISforAfrica | Value Added retail'} url={'https://gisfor.africa/products/valueaddedretail'}/>
        <Outlet />
      </ScrollIntoView>
    </div>
  )
}

export default Products