import React from "react";
import GIS_connect from "../../Assets/images/gis_connect.webp";

const Content_1 = () => {
  return (
    <div className="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 lg:gap-47 gap-48">
      <div className="lg:col-span-6 md:col-span-6 col-span-1 lg:space-y-40 space-y-24 lg:px-95 md:pl-64 px-16  flex flex-col lg:items-center md:items-center md:justify-center  lg:justify-center">
        <h2
          className="font-semibold lg:leading-51.6 lg:text-3xl text-xl leading-36  lg:px-0 pr-95 text-P300"
          data-aos="fade-up"
        >Evolving Africa with GIS intelligence
        </h2>
        <p
          className="text-P400 lg:text-xl text-lg leading-27 font-normal"
          data-aos="fade-up"
        >
          At GIS4Africa, our goal is to develop and welcome geospatial oriented
          solutions that addreses Africans challenges
        </p>
      </div>
      <div className="lg:col-span-6 md:col-span-6  col-span-1">
        <img
          className="object-cover w-full h-full"
          src={GIS_connect}
          alt="gis connect"
        />
      </div>
    </div>
  );
};

export default Content_1;
