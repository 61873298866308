import React from "react";
import VarProductCard from "../Atoms/VarProductCard";
import floodImage from "../../Assets/images/flood_img.jpg";
import agricImage from "../../Assets/images/agriculture_img.jpg";
import natureImage from "../../Assets/images/nature_img.jpg";
import urbanImage from "../../Assets/images/urbanization.jfif";
import waterImage from "../../Assets/images/Water_img.webp";

const ValueAddedProducts = () => {
  return (
    <div className="lg:mx-96 md:mx-64 mx-16 my-110 space-y-128">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-40">
        <div className="col-span-1 lg:order-first order-last">
          <VarProductCard
            title="Community Water Infrastructure Management"
            description="We go more than just mapping of pipes and facilities. We offer asset management,  
                system performance analysis, work optimization and collaboration."
            link="https://forms.gle/aFUtsakLkujEvkay9"
            butName="Make a Demand"
          />
        </div>
        <div className="col-span-1 lg:order-last order-first lg:h-430 h-299 rounded-md overflow-hidden">
          <img
            src={waterImage}
            alt="water"
            className="object-cover w-full h-full"
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-40">
        <div className="col-span-1 lg:h-430 h-299 order-first  rounded-md overflow-hidden">
          <img
            src={agricImage}
            alt="agriculture"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="col-span-1">
          <VarProductCard
            title="Community Support for Food and Agriculture"
            description="Take your farm management to the next level with artificial intelligence solutions. 
                Leverage a team of AI and ML experts to drive efficiency and growth to your farm.
                Contact us - Let's talk about your project
                "
            link="https://forms.gle/sZGTNe5LkNXPByew6 "
            butName="Make a Demand"
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-40">
        <div className="col-span-1 lg:order-first order-last">
          <VarProductCard
            title="Smart and Green Cities despite Rapid Urbanization"
            description="Adaptive mapping of the city information and integration of various thematic maps to efficiently
                 manage resources despite Rapid Urbanization."
            link="https://forms.gle/Ct3Dx2wpFFZrxGn16 "
            butName="Make a Demand"
          />
        </div>
        <div className="col-span-1 lg:h-430 h-299 lg:order-last order-first rounded-md overflow-hidden">
          <img
            src={urbanImage}
            alt="urban"
            className="object-cover w-full h-full"
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-40">
        <div className="col-span-1 lg:h-430 h-299 order-frist rounded-md overflow-hidden">
          <img
            src={floodImage}
            alt="flood"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="col-span-1">
          <VarProductCard
            title="Early Warning and Disaster Preparedness for Land Degradation, Flood Risks and Coastal Erosion."
            description="Disaster susceptibility and emergency provision in natural, artific, and technological hazards 
                Geographic Information Systems and satellite imagery analysis to create"
            link="https://forms.gle/vD8B8Zv8x3VtNVyMA "
            butName="Make a Demand"
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-40">
        <div className="col-span-1 lg:order-first order-last">
          <VarProductCard
            title="Land Administration and Management for Natural Ecosystems and Biodiversity Conservation in Africa."
            description="Mapping of  terrestrial ecosystems and local systems for protection or restoration."
            link="https://forms.gle/UfSNQ1HdwWpwZPB5A"
            butName="Make a Demand"
          />
        </div>
        <div className="col-span-1 lg:h-430 h-299 rounded-md overflow-hidden lg:order-last order-first">
          <img
            src={natureImage}
            alt=" water"
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default ValueAddedProducts;
