import React from "react";
import contact_img from "../Assets/images/contact.jpg";
import ContactForm from "../Component/Atoms/ContactForm";
import logo from "../Assets/logo/gisforafrica_logo.webp";
import { contactInfo } from "../Utils/data";
import Map from "../Component/Atoms/Map";
import ScrollIntoView from "../Component/Atoms/ScrollView";

const ContactUs = () => {
  return (
    <ScrollIntoView>
    <div>
      <section>
        <div
          className="h-384 bg-cover bg-center"
          style={{ backgroundImage: `url(${contact_img})` }}
        >
          <div
            className="h-full"
            style={{
              backgroundImage: `linear-gradient(to right, rgba(7,12,26,0.8), rgba(7,12,26,0.8))`,
            }}
          >
            <div className="h-full w-full py-169 lg:px-96 px-16 text-white">
              <h1 className="font-medium text-2xl leading-42">
                Let's build together
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 flex items-center justify-center lg:px-202 px-16 lg:gap-0 md:gap-0 gap-128 my-122">
        <div className="col-span-1">
          <div className="mb-60">
            <img src={logo} alt="logo_img" />
          </div>
          {contactInfo.map((contact, id) => {
            return (
              <div
                key={id}
                className="flex flex-col font-medium text-lg text-P200 leading-27 mb-40"
              >
                <span>{contact.address1}</span>
                <span>{contact.address2}</span>
                <span>{contact.phone}</span>
                <span>{contact.email}</span>
              </div>
            );
          })}
          <div>
            <h2 className="text-xl font-medium leading-36 text-P200 mb-16">
              Business Hours
            </h2>
            <div className="font-medium text-lg text-P200 leading-27 flex flex-col">
              <span>Mon-Fri: 8:00am to 4:00pm</span>
              <span>Saturday: closed </span>
              <span>Sunday: 8:00am to 4:00pm</span>
              <span>Public Holidays: closed</span>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
      <section >
        <Map/>
      </section>
    </div>
    </ScrollIntoView>
  );
};

export default ContactUs;
