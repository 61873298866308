import React from 'react'
import Partners from '../Component/Atoms/Partners';
import Content1 from '../Component/Atoms/Content_1';
import Content2 from '../Component/Atoms/Content_2';
import Content3 from '../Component/Atoms/Content_3';
import ContentStat from '../Component/Atoms/Content_stat';
import Content4 from '../Component/Atoms/Content_4';
import Animation from '../Component/Organisms/Animation';
import AnimatedText from '../Component/Atoms/AnimatedText';
import ScrollIntoView from '../Component/Atoms/ScrollView';
import SEO from './SEO'


const LandingPage = () => {
  return (
    <ScrollIntoView>
      <SEO title={'GISforAfrica'} url={'https://gisfor.africa'}/>
      <div className=''>
            <div className='relative flex flex-col justify-center items-center '>
              <Animation />
              <AnimatedText />
            </div>
            <Partners />
            <Content1 />
            <Content2 />
            <Content3 />
            <ContentStat />
            <Content4 />
      </div>
    </ScrollIntoView > 
  )
}

export default LandingPage
