import React from "react";
import TeamCard from "../Component/Atoms/TeamCard";
import { TeamInfo } from "../Utils/data";
import ourteam from "../Assets/images/Our_team.jpg";
import ScrollIntoView from "../Component/Atoms/ScrollView";

const Personnel = () => {
  return (
    <ScrollIntoView >
    <div>
      <section>
        <div
          className="h-384 bg-cover bg-center"
          style={{ backgroundImage: `url(${ourteam})` }}
        >
          <div
            className="h-full"
            style={{
              backgroundImage: `linear-gradient(to right, rgba(7,12,26,0.8), rgba(7,12,26,0.8))`,
            }}
          >
            <div className="bg-sub_texture h-full w-full py-169 lg:px-96 px-16 text-white">
              <h2 className="font-medium text-3xl leading-52">Our Team</h2>
              <p className="text-xl leading-36 font-normal w-11/12">
                Meet the team of vast professions in the industry
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="items-center flex flex-col justify-center">
        <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-32 gap-y-80 mt-128 mb-77">
          {TeamInfo.map((member, id) => {
            return (
              <div key={id} className="cols-span-1">
                <TeamCard
                  name={member.name}
                  picture={member.picture}
                  twitter_link={member.Twitter_handle}
                  linkedin_link={member.LinkedIn}
                  position={member.position}
                />
              </div>
            );
          })}
        </div>
      </section>
    </div>
    </ScrollIntoView>
  );
};

export default Personnel;
